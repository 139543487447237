import Flickity from "flickity";

import { switchActiveElement } from "../utils/activeElem";

const innovationList = document.querySelector(".mattress-innovation-list");
if (innovationList) {
    new Flickity(innovationList, {
        watchCSS: true,
        draggable: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: true,
    });
}

const tabList = document.querySelector(".mattress-index-tabs-list");
if (tabList) {
    new Flickity(tabList, {
        watchCSS: true,
        draggable: true,
        wrapAround: true,
        prevNextButtons: true,
        pageDots: false,
    });
}

const carousel = document.querySelector(".mattress-index-hero-carousel");
const carouselNavLinks = document.querySelectorAll<HTMLAnchorElement>(
    ".mattress-index-hero-carousel__nav-link",
);
const heroImages = document.querySelector<HTMLElement>(
    ".mattress-index-hero-carousel__image-container",
);
if (carousel && heroImages) {
    // Tab-like functionality for all-mattress hero 'carousel'
    // Is a duplication of Helpers.js [data-tabs] functionality with a slight augmentation. Need to refactor
    carouselNavLinks.forEach((elem) => {
        elem.addEventListener("click", (e) => {
            e.preventDefault();
            const target = parseInt(elem.href, 10);
            const auxTarget = document.querySelector<HTMLElement>(
                elem.dataset.target || "",
            );
            const block = document.querySelector<HTMLElement>(
                elem.dataset.block || "",
            );
            if (auxTarget && block) {
                switchActiveElement(elem, `${block}__nav-link`);
                switchActiveElement(auxTarget, `${block}__description`);
                heroFlkty.select(target);
            }
        });
    });

    const heroFlkty = new Flickity(heroImages, {
        draggable: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: true,
        autoPlay: 6000,
    });

    heroFlkty.on("select", () => {
        const index = heroFlkty.selectedIndex + 1;
        const targetLink = document.querySelector<HTMLElement>(
            `.mattress-index-hero-carousel__nav-item:nth-child(${index}) .mattress-index-hero-carousel__nav-link`,
        );
        const targetDescription = document.querySelector<HTMLElement>(
            `.mattress-index-hero-carousel__description:nth-child(${index})`,
        );
        if (targetLink && targetDescription && heroFlkty.selectedElement) {
            switchActiveElement(
                targetLink,
                "mattress-index-hero-carousel__nav-link",
            );
            switchActiveElement(
                targetDescription,
                "mattress-index-hero-carousel__description",
            );
            switchActiveElement(
                heroFlkty.selectedElement as HTMLElement,
                "mattress-index-hero-carousel__image",
            );
        }
    });
}
