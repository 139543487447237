import * as t from "io-ts";
import {
    IImageURL,
    ImageURL,
    ProductID,
} from "tsi-common-react/src/models/nominals";
import {
    BooleanBlock,
    BundleGroupChooserSubBlock,
    CharBlock,
    ImageChooserBlock,
    ListBlock,
    SnippetChooserBlock,
    StructBlock,
    VariantDescriptionBlock,
    VariantHeroBlock,
} from "tsi-common-react/src/models/streamfield-blocks";
import { Link as LinkSnippet } from "tsi-common-react/src/models/streamfield-snippets";
import { nullable } from "tsi-common-react/src/models/utils";

/**
 * See streamfield block: tsi-tempurpedic/cms.blocks.reviews.ReviewsBlock
 */
const ReviewsBlockReview = StructBlock(
    {
        author: CharBlock,
        location: CharBlock,
        content: CharBlock,
    },
    {},
);

/**
 * See streamfield block: tsi-tempurpedic/cms.blocks.reviews.ReviewsBlock
 */
export const ReviewsBlock = StructBlock(
    {
        reviews: ListBlock(ReviewsBlockReview),
    },
    {},
);

/**
 * See streamfield block: tsi-tempurpedic/cms.pages.pdp.PDPUpsellBlock
 */
export const PDPUpsellBlockSlide = StructBlock(
    {
        image: ImageChooserBlock,
    },
    {
        slide_bundle: BundleGroupChooserSubBlock,
        slide_cta: CharBlock,
        slide_link: nullable(SnippetChooserBlock(LinkSnippet)),
        initial_mobile_slide: BooleanBlock,
    },
);
export interface IPDPUpsellBlockSlide
    extends t.TypeOf<typeof PDPUpsellBlockSlide> {}

/**
 * See streamfield block: tsi-tempurpedic/cms.pages.pdp.PDPUpsellBlock
 */
export const PDPUpsellBlock = StructBlock(
    {},
    {
        header_title: CharBlock,
        header_copy: CharBlock,
        header_title_mobile: CharBlock,
        header_copy_mobile: CharBlock,
        slides: ListBlock(PDPUpsellBlockSlide),
        cta_copy: CharBlock,
    },
);
export interface IPDPUpsellBlock extends t.TypeOf<typeof PDPUpsellBlock> {}

export const CompatibilityAccordionPanel = StructBlock(
    {},
    {
        title: CharBlock,
        description: CharBlock,
        image: ImageChooserBlock,
        choice: nullable(t.boolean),
    },
);
export const CompatibilityPanel = t.interface({
    id: t.string,
    type: t.string,
    value: CompatibilityAccordionPanel,
});
export interface CompatibilityPanel
    extends t.TypeOf<typeof CompatibilityPanel> {
    value: {
        title: string;
        description: string;
        image: {
            url: IImageURL;
            title: string;
            height: number;
            width: number;
        };
        choice: boolean;
    };
}

export const CompatibilityAccordionPanels = StructBlock(
    {
        panels: t.array(CompatibilityAccordionPanel),
    },
    {},
);
export interface ICompatibilityAccordionPanel
    extends t.TypeOf<typeof CompatibilityAccordionPanel> {}

export const CompatibilityAccordionBlock = t.interface({
    compatibility_header: t.string,
    use_icon: nullable(t.boolean),
    icon_comp: nullable(ImageChooserBlock),
    icon_incomp: nullable(ImageChooserBlock),
    compatibility_panels: CompatibilityAccordionPanels,
    compatibility_footer: nullable(t.string),
});

export interface ICompatibilityAccordionBlock
    extends t.TypeOf<typeof CompatibilityAccordionBlock> {}

export const HotspotContent = t.interface({
    header_content: t.string,
    hotspot_one: t.string,
    hotspot_two: t.string,
    hotspot_three: t.string,
});

export interface IHotspotContent extends t.TypeOf<typeof HotspotContent> {}

/**
 * See Wagtail Page: tsi-pillows/cms.pages.pdp.ProductDisplayPage
 */
export const ProductPage = t.interface({
    title: t.string,
    description: t.string,
    top_button_text: t.string,
    box_header: t.string,
    bundle_modal_serialized: t.array(PDPUpsellBlock),
    product: ProductID,
    modules: t.union([t.undefined, t.string]),
    variant_hero_images_with_urls: t.array(VariantHeroBlock),
    hero_image_url: nullable(ImageURL),
    hero_image_alt: nullable(t.string),
    mobile_hero_image_url: nullable(ImageURL),
    variant_configurator_description_data: t.array(VariantDescriptionBlock),
});
export interface IProductPage extends t.TypeOf<typeof ProductPage> {}
