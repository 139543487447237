import Flickity from "flickity-imagesloaded";
import React, { useEffect, useRef, useState } from "react";
import { addProductToBasket } from "tsi-common-react/src/apps/configurator/actions";
import {
    IConcreteBundle,
    IProduct,
} from "tsi-common-react/src/models/catalogue.interfaces";
import { ProductUpsellModalBlockSlide } from "tsi-common-react/src/models/streamfield-blocks";
import { trackAddToBasketEvent } from "tsi-common-react/src/utils/analytics";

import UpsellModalSlide from "./UpsellModalSlide";

interface IProps {
    slides: ProductUpsellModalBlockSlide[];
    bundles: IConcreteBundle[];
    onProceed: () => void;
}

/**
 * A collection of UpsellModalSlide components
 */
export function UpsellModalSlideCollection(props: IProps) {
    let flickity: Flickity | null = null;
    const slidesElemRef = useRef<HTMLDivElement>(null);

    const [buyIsDisabled, setBuyIsDisabled] = useState<boolean>(false);
    useEffect(() => {
        initFlickity();
    }, []);

    async function onAddToBasket(product: IProduct) {
        setBuyIsDisabled(true);
        try {
            await addProductToBasket(product.url);
        } catch (e) {
            setBuyIsDisabled(false);
            return;
        }
        trackAddToBasketEvent(product);
        props.onProceed();
    }

    function initFlickity() {
        if (!slidesElemRef.current) {
            return;
        }
        if (flickity) {
            flickity.destroy();
        }
        const initialSlide = props.slides.findIndex((s) => {
            return s.initial_mobile_slide;
        });
        if (slidesElemRef.current.childNodes.length > 1) {
            flickity = new Flickity(slidesElemRef.current, {
                cellAlign: "center",
                wrapAround: false,
                imagesLoaded: true,
                prevNextButtons: false,
                initialIndex: initialSlide,
                watchCSS: true,
            });
        }
    }

    function isAvailable(bundle: IConcreteBundle) {
        const product = bundle.suggested_products[0];
        return product.availability.is_available_to_buy;
    }

    if (!props.slides) {
        return null;
    }

    const slides = props.slides.map((slide, i) => {
        const isBundle = slide.slide_bundle?.id !== 0;
        let bundle: IConcreteBundle | null = null;
        if (isBundle) {
            bundle = props.bundles.filter((b) => {
                return b.bundle_group.id === slide.slide_bundle?.id;
            })[0];
        }
        if (!bundle) {
            return null;
        }
        // Skip rendering if product is not available
        if (!isAvailable(bundle)) {
            return null;
        }
        return (
            <UpsellModalSlide
                key={i}
                isBundle={isBundle}
                bundle={bundle}
                imagePath={slide.image.url}
                imageAlt={slide.image.title}
                ctaPath={slide.slide_link}
                ctaCopy={slide.slide_cta}
                buyIsDisabled={buyIsDisabled}
                onAddToBasket={onAddToBasket}
            />
        );
    });

    return (
        <div className="upsell-modal__slides" ref={slidesElemRef}>
            {slides}
        </div>
    );
}

export default UpsellModalSlideCollection;
