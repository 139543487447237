import classNames from "classnames";
import React from "react";
import { Link } from "tsi-common-react/src/common/Link";

import { IMenuPageLink } from "../menu.interface";

interface IProps {
    linkObject: IMenuPageLink;
    isMobile: boolean;
    linkID: number;
}

export const PageLink = (props: IProps) => {
    const linkClass = classNames({
        "site-nav__main-menu-item": !props.isMobile,
        "site-nav__main-menu-item--hover-state": !props.isMobile,
        "main-dropdown-menu__mobile": props.isMobile,
        "main-dropdown-menu__mobile-link": props.isMobile,
        [`site-nav__main-menu-item--${props.linkObject.value.override_title
            .replace(/\s+/g, "-")
            .toLowerCase()}`]: !props.isMobile,
        [`main-dropdown-menu__mobile--${props.linkObject.value.page.slug}`]:
            props.isMobile,
    });

    return (
        <span key={props.linkID}>
            <Link
                title={props.linkObject.value.override_title}
                className={linkClass}
                href={props.linkObject.value.page.url}
                target="_top"
                rel="noreferrer"
            >
                {props.linkObject.value.override_title}
            </Link>
        </span>
    );
};
