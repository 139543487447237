import React from "react";
import { snapUserDataLayerState } from "tsi-common-react/src/api/datalayer";
import { registerCascades } from "tsi-common-react/src/apps/common/cascades";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

import { store } from "../store";
import { getMenuConfiguration } from "./menu";

// Register store cascades
registerCascades(store);

// Render standalone login forms
dynamicPlaceComponent(
    '[data-place-react="auth-login-standalone"]',
    async (elem) => {
        const { LoginForm } = await import(
            "tsi-common-react/src/apps/authentication/components/LoginForm"
        );
        const nextURL = elem.dataset.continueUrl
            ? isoWebPageURL.wrap(elem.dataset.continueUrl)
            : undefined;
        return <LoginForm nextURL={nextURL} />;
    },
);

// Load data for main menu from Wagtail JSON
const menuConfig = getMenuConfiguration();
if (menuConfig) {
    // Render Main Menu Dropdown
    dynamicPlaceComponent(
        '[data-place-react="main-menu-dropdown"]',
        async () => {
            const { MainMenu } = await import("./containers/MainMenu");
            return <MainMenu cmsData={menuConfig} />;
        },
    );
}

// Render user data layer element
dynamicPlaceComponent('[data-place-react="user-data-layer"]', async (elem) => {
    await readyStateComplete;
    // Pause for 1 second to allow the data layer push scripts to run after readyState is complete
    await (async () => {
        return new Promise((res) => {
            setTimeout(res, 1000);
        });
    })();
    const orderID = elem.dataset.order || "";
    await snapUserDataLayerState(orderID);
    return null;
});
