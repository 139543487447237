import React from "react";
import SVG from "react-inlinesvg";
import Modal from "react-modal";
import { isMobile } from "tsi-common-react/src/utils/detectMobile";
import { focusElement } from "tsi-common-react/src/utils/keyboardFocus";

import iconBars from "../../svg/bars.svg";
import iconX from "../../svg/x.svg";
import { MenuLinks } from "../components/main-menu-links";
import { NavFooter } from "../components/main-menu-nav-footer";
import { PromoAd } from "../components/main-menu-promo-ad";
import { SearchNav } from "../components/main-menu-search-nav";
import { IMenuItem } from "../menu.interface";

interface IProps {
    cmsData: IMenuItem[];
}

interface IState {
    modalIsOpen: boolean;
}

export class MainMenu extends React.Component<IProps, IState> {
    state: IState = {
        modalIsOpen: false,
    };

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.isWindowResizing();
        });
    }

    private isWindowResizing() {
        // For mobile view on android
        if (window.innerWidth < 500 && isMobile.Android()) {
            return;
        }

        if (this.state.modalIsOpen) {
            // If the modal is open and the window is resized, close the modal
            this.closeModal();
        }
    }

    private openModal(event?: React.MouseEvent<HTMLElement>) {
        let menuLinkElem = null;
        if (event) {
            event.preventDefault();
            menuLinkElem = event.currentTarget;
        }

        if (menuLinkElem) {
            if (
                !menuLinkElem.classList.contains(
                    "site-nav__main-menu-item--mobile",
                )
            ) {
                document.body.classList.add("body-dropdown-open--desktop");
            }
        }
        this.setState({
            modalIsOpen: true,
        });
    }

    private closeModal(
        event?: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) {
        let menuLinkElem: HTMLElement | null = null;
        if (event) {
            event.preventDefault();
            menuLinkElem = event.currentTarget as HTMLElement;
        }
        if (menuLinkElem) {
            if (
                !menuLinkElem.classList.contains(
                    "site-nav__main-menu-item--mobile",
                )
            ) {
                document.body.classList.remove("body-dropdown-open--desktop");
            }
        }
        this.setState({
            modalIsOpen: false,
        });
    }

    render() {
        const windowWidth = window.innerWidth;
        let contentPosition: React.CSSProperties["position"];
        let contentTop: React.CSSProperties["top"];
        let contentHeight: React.CSSProperties["height"];
        if (windowWidth < 501) {
            contentPosition = "fixed";
            contentTop = 0;
            contentHeight = "100%";
        } else {
            contentPosition = "relative";
            contentTop = "104px";
            contentHeight = "auto";
        }
        const modalStyle: Modal.Styles = {
            overlay: {
                position: "absolute",
                backgroundColor: "rgba(255, 255, 255, 0)",
            },
            content: {
                position: contentPosition,
                left: 0,
                right: 0,
                top: contentTop,
                width: "100%",
                height: contentHeight,
                overflow: "hidden",
                border: "none",
                boxShadow: "0 10px 10px -5px rgba(0,0,0,.3)",
                borderTop: "1px solid rgba(234, 234, 234, 1)",
            },
        };

        const promoAds: JSX.Element[] = [];
        const navFooter: JSX.Element[] = [];

        this.props.cmsData.forEach((menuItem) => {
            if (menuItem.type !== "nav_category") {
                return;
            }
            if (menuItem.value.sub_nav.length > 0) {
                // Build out Nav Promo Ad (if it exists)
                menuItem.value.sub_nav.forEach((val, i) => {
                    switch (val.type) {
                        case "nav_advert":
                            promoAds.push(
                                <PromoAd promoArrayEl={val} key={i} />,
                            );
                            break;
                        case "nav_footer":
                            navFooter.push(
                                <NavFooter navFooterEl={val} key={i} />,
                            );
                            break;
                        default:
                            break;
                    }
                });
            }
        });

        // Need link title since content is an image. See #13290
        return (
            <span className="site-nav__main-menu-item__container">
                <span>
                    <span>
                        <a
                            href="#"
                            aria-label="Open Menu"
                            onClick={(e) => {
                                this.openModal(e);
                            }}
                            className="site-nav__main-menu-item site-nav__main-menu-item--mobile"
                        >
                            <SVG
                                className="site-nav__mobile-toggle-icon"
                                src={iconBars}
                            />
                        </a>
                    </span>
                    <MenuLinks
                        cmsData={this.props.cmsData}
                        isMobile={false}
                        modalIsOpen={this.state.modalIsOpen}
                        openModal={this.openModal.bind(this)}
                        closeModal={this.closeModal.bind(this)}
                    />
                </span>
                <Modal
                    contentLabel="Shop pillows menu"
                    style={modalStyle}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={(e) => {
                        this.closeModal(e);
                    }}
                    onAfterOpen={() => {
                        focusElement(
                            ".main-dropdown-menu .main-dropdown-menu__close",
                        );
                    }}
                >
                    <h2 className="ada-screenreader-only">Shop Pillows Menu</h2>
                    <div className="main-dropdown-menu" aria-expanded="true">
                        <button
                            aria-label="Close Menu"
                            onClick={(e) => {
                                this.closeModal(e);
                            }}
                            className="main-dropdown-menu__mobile main-dropdown-menu__close main-dropdown-menu__mobile--is-active"
                        >
                            <SVG
                                aria-hidden="true"
                                className="main-dropdown-menu__close-icon"
                                src={iconX}
                            />
                        </button>
                        <div
                            className="main-dropdown-menu__content l-capped-width"
                            id="main-dropdown-menu-shop"
                        >
                            <MenuLinks
                                cmsData={this.props.cmsData}
                                isMobile={true}
                                modalIsOpen={this.state.modalIsOpen}
                                openModal={this.openModal.bind(this)}
                                closeModal={this.closeModal.bind(this)}
                            />
                            {promoAds}
                        </div>
                        <div className="main-dropdown-menu__footer-wrapper l-full-width">
                            <div className="main-dropdown-menu__footer l-capped-width">
                                {navFooter}
                            </div>
                        </div>
                        <SearchNav />
                    </div>
                </Modal>
            </span>
        );
    }
}
