import React from "react";
import SVG from "react-inlinesvg";

import iconIconSearch from "../../svg/icon-search.svg";

interface IProps {}

interface IStage {}

export class SearchNav extends React.Component<IProps, IStage> {
    private searchInputComponent: HTMLFormElement | null = null;

    private toggleSearchView(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        if (!this.searchInputComponent) {
            return;
        }

        this.searchInputComponent.classList.toggle("hidden");
    }

    render() {
        // Need link title since content is an image. See #13290
        return (
            <div className="search-nav__content search-nav__content--mobile-menu">
                <div className="search-nav__search">
                    <form
                        ref={(ref) => {
                            this.searchInputComponent = ref;
                        }}
                        className="search-nav__search-input hidden"
                        action="/search/"
                        method="get"
                    >
                        <input
                            className="search-nav__search-input--text"
                            type="text"
                            name="query"
                            placeholder="Search"
                        />
                    </form>
                    <a
                        aria-label="Toggle Search Menu"
                        href="#"
                        onClick={(e) => {
                            this.toggleSearchView(e);
                        }}
                    >
                        <SVG
                            className="search-nav__icon"
                            src={iconIconSearch}
                        />
                    </a>
                </div>
            </div>
        );
    }
}
