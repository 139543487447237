import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "tsi-common-react/src/common/Link";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";

import iconSpeechBubblesTalk from "../../svg/speech-bubbles-talk.svg";

interface IProps {}

interface IState {}

export class CheckoutSidebarContent extends React.Component<IProps, IState> {
    render() {
        const phoneLink = (
            <a
                className="basket-summary__phone-link"
                href={getPageSetting("pre-order-phone-number-link")}
            >
                Call {getPageSetting("pre-order-phone-number-display")}
            </a>
        );
        return (
            <div className="basket-summary__help">
                <h3>
                    <SVG
                        className="basket-summary__help-icon"
                        src={iconSpeechBubblesTalk}
                    />
                    Questions?
                </h3>
                <div className="basket-summary__text">
                    {phoneLink}
                    <p>
                        What is your{" "}
                        <Link
                            target="_blank"
                            href={urls.pageURL("return-policy")}
                        >
                            return policy?
                        </Link>
                    </p>
                    <p>
                        <Link
                            target="_blank"
                            href={urls.pageURL("shipping-and-delivery")}
                        >
                            When can I expect my order?
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}
